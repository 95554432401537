// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDddf13mBPuI3Mpbp5ZNkjRm7XvDANJqDY",
  authDomain: "balmlanding.firebaseapp.com",
  databaseURL: "https://balmlanding.firebaseio.com",
  projectId: "balmlanding",
  storageBucket: "balmlanding.appspot.com",
  messagingSenderId: "421190279474",
  appId: "1:421190279474:web:a8f48419b08a135e7af72b",
  measurementId: "G-1X439GRYR5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);