import { useState, useEffect } from 'react';
import { 
 Button, Dialog, DialogTitle, DialogContent,
 TextField, Checkbox, FormControlLabel,
 Container, Box, Typography, IconButton, Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit } from 'firebase/firestore';
import {app} from './firebase-config';

const db = getFirestore(app);

function App() {
 const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
 const [openModal, setOpenModal] = useState(false);
 const [matelotEmail, setMatelotEmail] = useState('');
 const [formData, setFormData] = useState({
   phone: '',
   birthdate: '',
   firstName: '',
   email: '',
   stayInformed: false,
   canContact: false
 });
 const [submitting, setSubmitting] = useState(false);
 const [submitStatus, setSubmitStatus] = useState('');

 function calculateTimeLeft() {
   const spring2025 = new Date('March 20, 2025');
   const difference = spring2025 - new Date();
   return {
     days: Math.floor(difference / (1000 * 60 * 60 * 24)),
     hours: Math.floor((difference / 1000 / 60 / 60) % 24),
     minutes: Math.floor((difference / 1000 / 60) % 60)
   };
 }

 useEffect(() => {
   const timer = setInterval(() => {
     setTimeLeft(calculateTimeLeft());
   }, 60000);
   return () => clearInterval(timer);
 }, []);

 const getNextId = async (collectionName) => {
   const q = query(collection(db, collectionName), orderBy('id', 'desc'), limit(1));
   const querySnapshot = await getDocs(q);
   let lastId = 0;
   
   querySnapshot.forEach((doc) => {
     const currentId = parseInt(doc.data().id.replace(/\D/g, ''));
     if (currentId > lastId) lastId = currentId;
   });
   
   return `${collectionName}${String(lastId + 1).padStart(2, '0')}`;
 };

 const handleChange = (e) => {
   const { name, value, type, checked } = e.target;
   setFormData(prev => ({
     ...prev,
     [name]: type === 'checkbox' ? checked : value
   }));
 };

 const handleMatelotSubmit = async (e) => {
   e.preventDefault();
   setSubmitting(true);
   
   try {
     const newId = await getNextId('matelot');
     await addDoc(collection(db, 'matelots'), {
       id: newId,
       email: matelotEmail,
       createdAt: new Date().toISOString()
     });
     
     setSubmitStatus('success');
     setMatelotEmail('');
   } catch (error) {
     console.error('Error:', error);
     setSubmitStatus('error');
   } finally {
     setSubmitting(false);
   }
 };

 const handleBalmeurSubmit = async (e) => {
   e.preventDefault();
   setSubmitting(true);
   
   try {
     const newId = await getNextId('balmeur');
     await addDoc(collection(db, 'balmeurs'), {
       id: newId,
       ...formData,
       createdAt: new Date().toISOString()
     });
     
     setOpenModal(false);
     setFormData({
       phone: '',
       birthdate: '',
       firstName: '',
       email: '',
       stayInformed: false,
       canContact: false
     });
     setSubmitStatus('success');
   } catch (error) {
     console.error('Error:', error);
     setSubmitStatus('error');
   } finally {
     setSubmitting(false);
   }
 };

 return (
   <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
     <Box sx={{
       minHeight: '100vh',
       bgcolor: 'primary.dark',
       color: 'white',
       p: 4
     }}>
       <Box sx={{ textAlign: 'center', mb: 8 }}>
         <img src="/logo-balm.png" alt="BALM" style={{ width: 200 }} />
         
         <Typography variant="h2" sx={{ mb: 2, mt: 4 }}>
           Créez du lien local
         </Typography>
         
         <Typography variant="h6" sx={{ mb: 6 }}>
           Une messagerie éphémère pour rompre l'isolement et favoriser les interactions locales
         </Typography>

         <Button 
           variant="contained"
           onClick={() => setOpenModal(true)}
           sx={{ 
             bgcolor: '#F8CD36',
             color: '#021019',
             '&:hover': { bgcolor: '#e5b82f' },
             mb: 8
           }}
         >
           Devenir Balmeur
         </Button>
       </Box>

       <Container maxWidth="lg">
         <Box sx={{ 
           display: 'flex', 
           justifyContent: 'space-between',
           alignItems: 'center',
           gap: 4,
           flexWrap: { xs: 'wrap', md: 'nowrap' }
         }}>
           <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
             {Object.entries(timeLeft).map(([key, value]) => (
               <Box 
                 key={key}
                 sx={{
                   bgcolor: 'rgba(4, 51, 76, 0.5)',
                   p: 3,
                   borderRadius: 2,
                   minWidth: 80,
                   textAlign: 'center'
                 }}
               >
                 <Typography variant="h4" sx={{ color: '#F8CD36' }}>
                   {value}
                 </Typography>
                 <Typography>{key}</Typography>
               </Box>
             ))}
           </Stack>

           <Box 
             component="img"
             src="/screenshot.jpg"
             alt="Application BALM"
             sx={{ 
               width: { xs: '100%', md: '45%' },
               borderRadius: 2,
               boxShadow: 3
             }}
           />
         </Box>
       </Container>
     </Box>

     <Box component="footer" sx={{ bgcolor: '#021019', py: 4, mt: 'auto' }}>
       <Container>
         <Box 
           component="form" 
           onSubmit={handleMatelotSubmit}
           sx={{
             display: 'flex',
             gap: 2,
             maxWidth: 500,
             mx: 'auto'
           }}
         >
           <TextField
             fullWidth
             placeholder="Votre email"
             value={matelotEmail}
             onChange={(e) => setMatelotEmail(e.target.value)}
             required
             sx={{ 
               '& .MuiInputBase-root': { 
                 color: 'white',
                 bgcolor: 'rgba(4, 51, 76, 0.5)'
               }
             }}
           />
           <Button 
             type="submit"
             variant="contained"
             disabled={submitting}
             sx={{ 
               bgcolor: '#F8CD36',
               color: '#021019',
               '&:hover': { bgcolor: '#e5b82f' }
             }}
           >
             {submitting ? 'Envoi...' : 'Devenir Matelot'}
           </Button>
         </Box>
       </Container>
     </Box>

     <Dialog 
       open={openModal} 
       onClose={() => setOpenModal(false)}
       maxWidth="sm"
       fullWidth
     >
       <DialogTitle>
         Devenez Balmeur
         <IconButton
           onClick={() => setOpenModal(false)}
           sx={{ position: 'absolute', right: 8, top: 8 }}
         >
           <CloseIcon />
         </IconButton>
       </DialogTitle>
       <DialogContent>
         <Box 
           component="form" 
           onSubmit={handleBalmeurSubmit}
           sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}
         >
           <TextField
             fullWidth
             label="Prénom"
             name="firstName"
             value={formData.firstName}
             onChange={handleChange}
             required
           />
           <TextField
             fullWidth
             label="Email"
             type="email"
             name="email"
             value={formData.email}
             onChange={handleChange}
             required
           />
           <TextField
             fullWidth
             label="Téléphone"
             type="tel"
             name="phone"
             value={formData.phone}
             onChange={handleChange}
             required
           />
           <TextField
             fullWidth
             type="date"
             name="birthdate"
             value={formData.birthdate}
             onChange={handleChange}
             required
             InputLabelProps={{ shrink: true }}
           />
           <FormControlLabel
             control={
               <Checkbox
                 name="stayInformed"
                 checked={formData.stayInformed}
                 onChange={handleChange}
               />
             }
             label="Je souhaite rester informé(e) des actualités de Balm"
           />
           <FormControlLabel
             control={
               <Checkbox
                 name="canContact"
                 checked={formData.canContact}
                 onChange={handleChange}
               />
             }
             label="J'accepte d'être recontacté(e) pour parler de Balm"
           />
           <Button 
             type="submit"
             variant="contained"
             disabled={submitting}
             sx={{ 
               bgcolor: '#F8CD36',
               color: '#021019',
               '&:hover': { bgcolor: '#e5b82f' }
             }}
           >
             {submitting ? 'Envoi en cours...' : 'Valider'}
           </Button>
         </Box>
       </DialogContent>
     </Dialog>
   </Box>
 );
}

export default App;